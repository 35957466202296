<!-- Supplier -->
<template>
  <div class="card card-custom" v-loading="loading">
    <div class="card-body p-0">
       <!--begin: Settings-->
       <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
          <!--begin: Wizard Body-->
          <div class="row justify-content-center">
             <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <el-form ref="form" :model="form" auto-complete="nope">
                   <!--begin: Wizard Step 1-->
                   <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">   
                    <h3 class="mb-10 font-weight-bold text-dark"><i class="flaticon2-user"></i> {{title_text}}</h3>                  
                      <div class="form-group row mt-10">
                        <div class="col-sm-3">
                          <div tabindex="0" class="el-upload el-upload--text">
                            <button type="button" class="el-button el-button--primary el-button--small btn-button-upload">
                              <span>Select File</span>
                            </button>
                            <input type="file" name="file" accept=".csv" class="upload-btn" @change="loadCSV($event)">
                          </div>                          
                            <!-- <input type="file" id="csv_file" name="csv_file" class="form-control" @change="loadCSV($event)" > -->
                          <span class="el-upload__tip" slot="tip"><span class="text-red">*</span>Accept only .csv file</span>
                          <div class="form-err" v-if="form.errors.has('product')" >* Upload product.csv file is required</div>                       
                          <div class="mt-3 active" v-if="upload_file">File Name : {{upload_file}}</div>
                        </div>                       
                         <div class="col-sm-9 ">                           
                            <span class="download_sample">                            
                            <download-csv                             
                              :data   = "json_data"
                              name    = "product.csv">
                              <i class="flaticon-attachment icon-md"></i>                          
                              Download Sample CSV                           
                          </download-csv>
                            </span>
                         </div>
                      </div>                     
                        <div class=" row">                          
                          <div class="col-sm-12 mt-4 text-white mb-5">
                            <el-button  size="small" type="success" @click="uploadProduct">IMPORT PRODUCT</el-button>
                          </div>              
                          <div class="col-sm-12">
                            <table v-if="parse_csv" style="display: block;overflow-x: auto;white-space: nowrap;">
                              <thead>
                                  <tr>
                                    <th v-for="(key,k) in parse_header" :key="k"
                                        @click="sortBy(key)"
                                        :class="{ active: sortKey == key }">
                                        {{ key | capitalize }}
                                        <span class="arrow" :class="sortOrders[key] > 0 ? 'asc' : 'dsc'">
                                        </span>
                                    </th>
                                  </tr>
                              </thead>
                              <tr v-for="(csv,c) in parse_csv" :key="c">
                                  <td v-for="(key,j) in parse_header" :key="j">
                                    {{csv[key]}}
                                  </td>
                              </tr>
                            </table>
                          </div> 
                        </div>                     
                     </div>
                  </el-form>
              </div>
           </div>
        </div>
     </div>
  </div>
</template>



<style>
.settingicon{font-size:20px;}
select.select2{opacity:1;}
.preview-container img{max-width:200px;}
.add-logo-btn {margin-left:40px;}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from '@/core/services/api.form.services'
import { globalEvents } from '@/utils/globalEvents';

export default {
  name: 'add-supplier',
    data() {
    return {
      loading:false,
      title_text: 'Import Products',
      upload_file:null,     
      parse_header: [],
      parse_csv: [],
      sortOrders:{},
      sortKey: '',
      form : new Form({
        product:null,       
         
      }),     
        json_data: [
            {   
                'Category Name':'Building Materials', 
                'Product Name':'MAIN TEE OR T-BAR 12 FEET CERTAINTEED 20 BOX',
                'Unit Type': '10',  
                'Order Type':'Regular Orders',
                'Shipping Method': 'Truck', 
                'Product Available': 'Store',                             
                'Brand': 'Test Brand', 
                "Weight":"2",               
                'Length': '1',
                'width ': '1',
                'Height': '3',
                'Product Status': 'Active',
                'Square Feet': '10',
                'Supplie1 Name': 'Peel',                
                'Supplier1 UPC':'234567891312',
                'Supplier1 mfg_id':'DFRY1234',
                'Supplier1 Cost':'$11',
                'Supplie2 Name': 'Peel',                
                'Supplier2 UPC':'234567891312',
                'Supplier2 mfg_id':'DFRY1234',
                'Supplier2 Cost':'$11',
                'Cost Price':'11',
                'Regular %': '10',
                'Regular Price': '12.10',      
                'Special Price': '12',                
                'Inside %': '3',
                'Inside Price': '3',
                'Contractor %': '10',
                'Contractor Price':'11',
                'Dealer %': '10',
                "Dealer Price":"12.10",               
                'Whole Sale %': '5',
                'Whole Min Amount ': '230',
                'Whole Min Qty': '20',
                'Whole Sale Price': '11.6',
                'Tax Name': 'HST',
                'Tax %': '4',
                'Manufacturer No':'DFGHT455HG',
                'Manufacturer Serial':'34566SDF',
                'Description':'Product Description',
                'Inventory Stock': '1000',                
                'Stock Min Qty': '100',
                'Stock Max Qty': '2000',
                'Reorder' : '1000',
                'Image Url':'https://ctsbuilding.niiyo.net/pub/media/catalog/product/cache/8fdefa1d5c1a3f139006424de2961c86/SBG2000.jpg' ,
                'sku' : 'SBG1000'                     
            },
            {   'Category Name':'Building Materials', 
                'Product Name':'MAIN TEE OR T-BAR 12 FEET CERTAINTEED 20 BOX',
                'Unit Type': '10',  
                'Order Type':'Regular Orders',
                'Shipping Method': 'Truck', 
                'Product Available': 'Store/Web',                             
                'Brand': 'Test Brand', 
                "Weight":"2",               
                'Length': '1',
                'width ': '1',
                'Height': '3',
                'Product Status': 'Active',
                'Square Feet': '10',
                'Supplie1 Name': 'Peel',                
                'Supplier1 UPC':'234567891312',
                'Supplier1 mfg_id':'DFRY1234',
                'Supplier1 Cost':'$11',
                'Supplie2 Name': 'Peel',                
                'Supplier2 UPC':'234567891312',
                'Supplier2 mfg_id':'DFRY1234',
                'Supplier2 Cost':'$11',
                'Cost Price':'11',
                'Regular %': '10',
                'Regular Price': '12.10',      
                'Special Price': '12',                
                'Inside %': '3',
                'Inside Price': '3',
                'Contractor %': '10',
                'Contractor Price':'11',
                'Dealer %': '10',
                "Dealer Price":"12.10",               
                'Whole Sale %': '5',
                'Whole Min Amount ': '230',
                'Whole Min Qty': '20',
                'Whole Sale Price': '11.6',
                'Tax Name': 'HST',
                'Tax %': '4',
                'Manufacturer No':'DFGHT455HG',
                'Manufacturer Serial':'34566SDF',
                'Description':'Product Description',
                'Inventory Stock': '1000',                
                'Stock Min Qty': '100',
                'Stock Max Qty': '2000',
                 'Reorder' : '1000',
                'Image Url':'https://ctsbuilding.niiyo.net/pub/media/catalog/product/cache/8fdefa1d5c1a3f139006424de2961c86/SBG2006.jpg',
                'sku' : 'SBG1001'                
            }
        ]
    }
  },
   filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
       { title: "Dashboard", route: "/dashboard" },
       { title: "Products", route: "/product/list" },
       { title: "Import Products", },
    ]);
  },
  methods: {
     sortBy: function (key) {
      var vm = this
      vm.sortKey = key
      vm.sortOrders[key] = vm.sortOrders[key] * -1
    },
    csvJSON(csv){
      var vm = this
      var lines = csv.split("\n")
      var result = []
      var headers = lines[0].split(",")
      vm.parse_header = lines[0].split(",") 
      lines[0].split(",").forEach(function (key) {
        vm.sortOrders[key] = 1
      })
      
      lines.map(function(line, indexLine){
        if (indexLine < 1) return // Jump header line        
        var obj = {}
        var currentline = line.split(",")        
        headers.map(function(header, indexHeader){
          obj[header] = currentline[indexHeader]
        })        
        result.push(obj)
      })
      
      result.pop() // remove the last item because undefined values
      
      return result // JavaScript object
    },
    loadCSV(e) { 
     
      var vm = this 
      this.upload_file = e.target.files[0].name;      
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        this.form.product = e.target.files[0]; 
        // Handle errors load
        reader.onload = function(event) {
          var csv = event.target.result;
          vm.parse_csv = vm.csvJSON(csv)
          
        };
        reader.onerror = function(evt) {
          if(evt.target.error.name == "NotReadableError") {
            alert("Canno't read file !");
          }
        };
      } else {
        alert('FileReader are not supported in this browser.');
      }
    }, 
   
   uploadProduct(){
       	  this.loading = true;
            let url = 'product/imports'
	    		this.form.post(url).then((response) => { 
                 
		        if(response.status){
              this.$showResponse('success', response.message);
              this.$router.push({path:'/product/list'})
              this.loading=false;             
		        }		      
		      }).catch(error => {
                console.log(error);
                this.loading = false;
            });
    },
    
    closeForm() {
        if (!this.form.submiting) {
            globalEvents.$emit('SupplierCreated', false)
            this.$emit('close')
        }
    },

  },
  created(){
   
  }

}
</script>

<style scoped>

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
.icon-md{
  color:#3699ff !important
}

.download_sample{
    color: #3699ff;
    cursor: pointer;
}

</style>

